.page-products .columns {
  padding-top: 0;
  @include breakpoint($desktop) {
    padding-top: 60px;
  }
}

.page-products .sorter {
  // sass-lint:disable-all
  position: unset !important;
  // sass-lint:enable-all
}

.catalog-category-view {
  .page-title {
    margin: 30px 0 40px;
    text-transform: unset;
  }

  .category-cms {
    h3 {
      text-transform: unset;
      font-size: 20px;
      border-bottom: solid 1px #ccc;
      padding: 0 0 30px;
      margin: 0 0 30px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 15px;
        height: 15px;
        background: #ccc;
        bottom: -8px;
        border-radius: 100%;
        outline: 22px solid #fff;
      }
    }
  }

  .category-show-more {
    display: none;
  }

  .category-description {
    margin: 60px 0 0;
    padding: 60px 0 0;
    border-top: solid 1px #ccc;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      width: 15px;
      height: 15px;
      background: #ccc;
      top: -8px;
      border-radius: 100%;
      outline: 25px solid #fff;
    }
  }

  .subcategory__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px 0 0;
  }

  .category {
    &__wrapper {
      width: calc(100% / 2);
      @include breakpoint($desktop) {
        width: calc(100% / 4);
      }
      padding: 0 20px;
      margin: 0 0 20px;

      &:nth-child(1),
      &:nth-child(2) {
        width: 100%;
        @include breakpoint($tablet) {
          width: 50%;
        }

        .category__description {
          display: block;
        }
      }
    }

    &__title {
      text-transform: uppercase;
      font-size: 20px;
      margin: 0 0 15px;
      text-decoration: underline;
    }

    &__description {
      display: none;
      @include breakpoint($tablet) {
        display: block;
      }
    }
  }
}

.category-content-categories {
  &__heading {
    &.title {
      font-size: 20px;
      text-transform: none;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 -1% -40px;
    list-style: none;

    li {
      margin: 0 1% 30px;
      width: 23%;

      &:first-child,
      &:nth-child(2) {
        width: 48%;
      }

      h2,
      h3 {
        font-size: 20px;
        margin: 18px 0 8px;
        text-decoration: underline;
      }
    }

    img,
    a {
      display: block;
    }
  }

  .divider {
    margin: 25px 0 30px;
  }
}

.theme-pearl.store-view-default {
  .category-description {
    h2 {
      font-size: 20px;
      text-transform: none;
    }
  }
}

.products-grid {
  .product-item {
    &-name {
      a {
        font-weight: bold;
        // sass-lint:disable-all
        font-size: 14px !important;
        // sass-lint:enable-all
      }
    }
  }
}

.brand-engine-info-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .brand-wrapper {
    display: inline-block;
    width: 40%;
  }

  .engine-wrapper {
    display: inline-block;
    width: 40%;
  }

  .promo-image-container {
    display: inline-block;
    width: 40%;
  }

  img {
    max-width: 90%;
  }
}
