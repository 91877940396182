.checkout-index-index {
  .modal-inner-wrap {
    footer.modal-footer {
      button.action {
        margin-top: 0;
        span {
          display: block;
        }
      }
    }
  }
}

// Checkout
.checkout-container .payment-option-content .form-discount {
  .actions-toolbar .primary .action {
    border: 0;
    height: 40px;
    background: #000;
    color: #fff;
    margin: 0 0 0 5px;
    // sass-lint:disable-all
    box-shadow: none !important;
    // sass-lint:enable-all

    &:hover,
    &:focus,
    &:active {
      background: #000;
      box-shadow: none;
      border: 0;
    }

    span {
      color: #fff;
    }
  }

  .input-text {
    height: 40px;
  }
}

.opc-block-summary {
  .content.minicart-items {
    // sass-lint:disable-all
    display: block !important;
    // sass-lint:enable-all
  }
}

.opc-block-summary .items-in-cart.active > .title::after,
.opc-block-summary .items-in-cart > .title::after {
  // sass-lint:disable-all
  display: none !important;
  // sass-lint:enable-all
}

:root .theme-pearl.checkout-index-index .page-header .logo {
  // sass-lint:disable-all
  margin: 0 !important;
  // sass-lint:enable-all
}

.table-checkout-shipping-method {
  thead {
    display: none;
  }
}

.checkout-index-index {
  .page-wrapper .page-main {
    padding-top: 0;
  }
}

.table-checkout-shipping-method tbody td:first-child {
  width: unset;
}

[data-testid="deko-wallet-overlay"] {
    z-index: 2147483647;
}
