button.action.alert {
  // sass-lint:disable-all
  background-color: #FF4314 !important;
  border-color: #FF4314 !important;
  // sass-lint:enable-all
  width: 100%;

  &:hover {
    // sass-lint:disable-all
    border-color: #FF4314 !important;
    color: #FF4314 !important;
    // sass-lint:enable-all
    background: #ffffff;
  }
}

// sass-lint:disable-all
#widget_frame {
  width: 100%;
}
// sass-lint:enable-all

.theme-pearl .product-info-main .product.attribute.overview {
  width: 100%;
}

.product-info-main .product .alert {
  margin: 0;
  padding: 15px 0;
}

.delivery-time,
.out-of-stock-lead {
  padding: 15px;
  border: solid 1px #ccc;
  margin: 0 0 15px;
}

.out-of-stock-lead {
  background: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}

.incl-vat-label {
  display: none;
}

.catalog-product-view .incl-vat-label {
  display: inline-block;
  font-size: 14px;
}

.product-brand {
  .brand-image {
    img {
      width: 80px;
      // sass-lint:disable-all
      height: auto !important;
      // sass-lint:enable-all
    }
  }

  .brand-name {
    display: none;
  }
}

.product-brand-logos-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .engine-logo,
  .promo-image-container,
  .brand-logo {

    a {
      display: block;
    }

    img {
      max-height: 68px;
      width: auto;
    }

  }
}

.theme-pearl.store-view-default .product-info-main h1 {
  font-size: 26px;
}

.product-info-main .product.attribute.sku .type::after {
  display: none;
}

.product-options-bottom .price-box .old-price,
.product-info-price .price-box .old-price,
.product-options-bottom .price-box .price-container,
.product-info-price .price-box .price-container,
.product-info-main .product-info-price .old-price .price-wrapper .price {
  font-size: 14px;
}

// sass-lint:disable-all
.theme-pearl.catalog-product-view #maincontent .product-info-main .product-info-price .price-box {
  width: 70%;
}
// sass-lint:enable-all

.theme-pearl.catalog-product-view .product-info-main .product-info-stock-sku {
  padding-left: 0;
}

.special-price .price {
  // sass-lint:disable-all
  color: #FF0A12 !important;
  // sass-lint:enable-all
}

.filter-label {
  margin: 0 0 0 15px;
}

.products-grid .product-item .product-item-name a {
  // sass-lint:disable-all
  font-size: 14px !important;
  // sass-lint:enable-all
  display: block;
  word-break: keep-all;
  hyphens: initial;
  min-height: 75px;
  @include breakpoint($desktop) {
    min-height: 60px;
  }
}

.product-options-bottom .price-box,
.product-info-price .price-box {
  padding: 0;
}

.store-view-default .rrp {
  margin: 0 0 15px;
  min-height: 18px;
}

.catalog-product-view {

  .product.info.detailed .additional-attributes {
    width: 100%;
  }

  .table > thead > tr > th,
  .table > tbody > tr > th,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > td,
  .table > tfoot > tr > td {
    // sass-lint:disable-all
    border-bottom: solid 1px #dddddd !important;
    // sass-lint:enable-all
  }

  .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr {
    display: table-row;
    &:hover {
      background: #F6F6F7;
    }
  }

  .abs-no-display-s,
  .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison).additional-attributes tbody th,
  .order-details-items .table-order-items .options-label,
  .opc-wrapper .shipping-address-item.selected-item .action-select-shipping-item {
    display: table-cell;
    width: 50%;
    @include breakpoint($desktop) {
      width: 30%;
    }
    padding: 10px;
    background-color: #F6F6F7;
  }

  .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison).additional-attributes tbody td:last-child {
    display: table-cell;
    width: 50%;
    @include breakpoint($desktop) {
      width: 70%;
    }
    padding: 10px;
  }
  .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr td[data-th]::before,
  .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr th[data-th]::before {
    display: none;
  }
}

tr.Promotional-Image {
  // sass-lint:disable-all
  display: none !important;
  // sass-lint:enable-all
}

.data.item.content {
  a {
    font-weight: bold;
    text-decoration: underline;
    color: #1D8A33;
  }
}

// .theme-pearl.product-page-v1 .product-info-main .fieldset .actions {
//   // sass-lint:disable-all
//   width: calc(100% - 65px) !important;
//   // sass-lint:enable-all
// }

.catalog-product-view .column.main .products-grid.products-related .product-item .product-item-info .product-item-details .field.choice.related {
  // sass-lint:disable-all
  width: 100% !important;
  // sass-lint:enable-all
}

.product-info-main .product.alert {
  padding: 15px 0 0;
  a {
    &::before {
      content: "\f0e0";
      font: normal normal normal 14px/1 FontAwesome;
    }
  }
}
