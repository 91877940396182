footer.page-footer {
  // sass-lint:disable-all
  padding: 10px 0 20px !important;
  // sass-lint:enable-all
}

.inline-social {
  display: inline-block;
  @include breakpoint($desktop) {
    // sass-lint:disable-all
    padding: 15px 15px 0 0 !important;
    // sass-lint:enable-all
  }

  img {
    height: 26px;
    width: auto;
  }
}

.page-wrapper .page-footer .footer.content .footer-v1-content {
  display: block;
  max-width: 1200px;
  margin: 0 auto;
}

.trustpilot-box {
  @include breakpoint($desktop) {
    padding: 45px 0 0 0;
  }
}

.page-wrapper .page-footer .footer.content .footer-v1-content .mini-logo {
  padding-top: 10px;
}

.page-wrapper .page-footer .footer.content .footer-v1-content .footer-title {
  padding: 10px 0;
}

.close-search {
  display: none;
}

.mobile-search {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .8);
  z-index: 999;
  padding: 40px;
  color: #ffffff;

  &.active {
    display: block;
  }

  a {
    color: #ffffff;
  }

  .close-search {
    display: block;
    text-align: right;
    i {
      font-size: 22px;
    }
  }

  .form.minisearch {
    display: flex;
  }

  .block-search .label {
    display: none;
  }

  .field.search {
    width: 100%;
  }

  .block-search input {
    font-size: 18px;
    left: unset;
    position: unset;
    margin: 20px 0 0;
    display: inline-block;
    background: transparent;
    color: #ffffff;
    border: solid 2px #ffffff;
    height: 44px;
    padding: 5px;
    &::placeholder {
      color: #ffffff;
    }
  }

  .block-search .action.search {
    display: none;
    background: #ffffff;
    border: 0;
    span {
      color: #000000;
    }
  }

  .customHtml {
    // sass-lint:disable-all
    display: block !important;
    // sass-lint:enable-all
  }

}
