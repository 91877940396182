// Grid breakpoints
$mobile: 480px;
$tablet: 753px;
$desktop: 992px;
$desktop-mid: 1200px;
$desktop-large: 1440px;
.hide {
  display: none;
}

.mob-show {
  display: block;
  @include breakpoint($tablet) {
    display: none;
  }
}

.tablet-show {
  display: none;
  @include breakpoint($tablet) {
    display: block;
  }
  @include breakpoint($desktop) {
    display: none;
  }
}

.desktop-show {
  display: none;
  @include breakpoint($desktop) {
    display: block;
  }
}

.theme-pearl.store-view-default {
  .product-info-main {
    h1 {
      line-height: 1.3;

      @include breakpoint($tablet) {
        font-size: 24px;
      }

      @include breakpoint($desktop) {
        font-size: 30px;
      }

    }
  }
}

// sass-lint:disable-all
// body.theme-pearl ::-webkit-scrollbar,
// body.theme-pearl ::-webkit-scrollbar-thumb,
// body.theme-pearl ::-webkit-scrollbar-track {
//   background-color: transparent !important;
// }

// sass-lint:enable-all
