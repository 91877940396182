.message.global.cookie {
  background: #ffee9c;
  border: solid 6px #d6ca8e;
  position: fixed;
  bottom: 18px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 36px);
  max-width: 1920px;
  z-index: 9999;
  padding: 12px;
  .content {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  h3 {
    font-size: 18px;
    margin: 0 0 5px;
    font-weight: bold;
  }
  .actions {
    margin: 0;
  }
  button {
    padding: 15px;
  }
  a {
    text-decoration: underline;
  }
}

.cookie-overlay {
  background: rgba(0, 0, 0, .6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9998;
}
