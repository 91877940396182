.catalog-category-view {
  .category__image {
    min-height: 91px;
  }

  .slick-slider {
    position: relative;

    .slick-prev,
    .slick-next {
      // sass-lint:disable-all
      bottom: 40px !important;
      // sass-lint:enable-all
      top: unset;
      border: 0;
      width: 40px;
      height: 40px;
      background-color: rgba(255, 255, 255, .22);

      &::before {
        opacity: unset;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 1);
        border: 0;

        &::before {
          color: #1da048;
        }
      }
    }

    .slick-prev {
      right: 40px;
      left: unset;
      z-index: 9;

      &::before {
        font-weight: bold;
        content: "<";
        position: relative;
        top: -5px;
      }
    }

    .slick-next {
      right: 0;

      &::before {
        font-weight: bold;
        content: ">";
        position: relative;
        top: -5px;
      }
    }

    .slider {
      &--container {
        position: relative;
        display: flex;
        flex-direction: column;
        @include breakpoint($tablet) {
          flex-direction: row;
          margin: 0 0 60px;
        }
        align-items: center;
      }

      &--content {
        @include breakpoint($tablet) {
          width: 50%;
          height: 296px;
        }
        padding: 30px;
        color: #ffffff;
      }

      &--title {
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 600;
        margin: 0 0 10px;
        text-transform: none;
        color: #ffffff;
      }

      &--text {
        margin: 0 0 10px;
      }

      &--button {
        border: solid 1px #ffffff;
        color: #ffffff;
        padding: 8px 3px;
        height: 40px;
        min-width: 169px;
        text-align: center;
        margin: 15px 0 0;
        display: inline-block;
        width: 100%;
        @include breakpoint($desktop) {
          width: inherit;
          padding: 8px 10px;
        }

        &:hover {
          background: #ffffff;
          color: #1DA048;
        }
      }

      &--media {
        width: 100%;

        &-image {
          width: 100%;
          overflow: hidden;
        }
      }
    }
  }

  .category-cms {
    h3 {
      font-size: 20px;
    }
  }

  .rrp {
    margin: 15px 0 0;
    text-align: center;
  }
}

.product-item-details {
  text-align: left;
}

.actions-primary {
  .stock.unavailable {
    span {
      // sass-lint:disable-all
      background-color: #FF4314 !important;
      border: solid 1px #FF4314 !important;
      // sass-lint:enable-all
      width: 100%;
      color: #ffffff;
      font-size: 18px;
      padding: 7.5px 15px;
      position: relative;
      top: -17px;

      &:hover {
        // sass-lint:disable-all
        border: solid 1px #FF4314 !important;
        background: #ffffff !important;
        color: #FF4314 !important;
        // sass-lint:enable-all
      }
    }
  }
}

.block-viewed-products-grid {
  .actions-primary {
    .stock.unavailable {
      span {
        display: block;
      }
    }
  }
}

.category-description {
  p {
    // sass-lint:disable-all
    margin: 0 0 30px !important;
    // sass-lint:enable-all
  }
}

.catalog-category-view {
  .actions-primary {
    .stock.unavailable {
      span {
        // sass-lint:disable-all
        top: unset !important;
        // sass-lint:enable-all
        display: inline-block;
      }
    }
  }
}

.column.main .products-grid .product-item .product-item-info .product-item-details .product-item-inner .product-item-actions {
  text-align: center;
}

.block.filter {

  dt {
    display: none;
  }

  .item {
    text-transform: uppercase;
    font-size: 12px;
    position: relative;
    border-bottom: solid 1px #c8c8c8;
    padding: 0 0 10px;

    a {
      display: block;

      &::before {
        // sass-lint:disable-all
        margin: 3px 5px 0 0 !important;
        // sass-lint:enable-all
      }
    }

    .count {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
    }

  }

  .items {

    &--child {
      display: none;
      // sass-lint:disable-all
      text-transform: none !important;
      // sass-lint:enable-all
    }

  }

}

.brand-engine-info-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 16px 0 0;
}

.theme-pearl.page-products .columns .column .products-grid .product-item {
  margin-bottom: 0;
}

.filter .filter-current {
  margin: 45px 0 0;
  border: 0;
  @include breakpoint($desktop) {
    margin: 0;
  }
}

.products-grid .product-item .product-item-details .price-box .old-price {
  display: block;
  margin-left: 0;
}

.shop-by-brand {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  a {
    padding: 15px;
  }

}

button.out-of-stock {
  // sass-lint:disable-all
  background-color: #ff4314 !important;
  border: solid 1px #ff4314 !important;
  &:hover {
    background-color: #fff !important;
    color: #ff4314 !important;
    span {
      color: #ff4314 !important;
    }
  }
  // sass-lint:enable-all
}

.page-products .products-grid .product-item .product-item-info:hover .weltpixel-quickview.weltpixel_quickview_button_v2 {
  top: 0;
}
.category-view.mobile {
  display: none;
}
.theme-pearl.page-products .sidebar-main .sorter,
.page-with-filter .columns .sidebar-main .sorter {
  display: none;
}
body.filter-active .page-wrapper {
  margin-top: 0 !important;
}
@media only screen and (max-width: 767px) {
  .filter-title strong {
    top: 10px;
  }
  .filter.active .filter-title strong::after {
    color: #1D8A33;
  }
  .page-with-filter .columns .sidebar-main {
    order: 1;
    margin-bottom: 0 !important;
    position: relative;
    padding-top: 50px;
    .sorter {
      display: block;
      position: absolute !important;
      top: 10px;
    }
    .filter .filter-current {
      margin-top: 10px;
    }
  }
  .page-with-filter .columns .column.main {
    order: 2;
  }
  .page-with-filter .category-view:not(.mobile) {
    display: none;
  }
  .page-with-filter .category-view.mobile {
    display: block;
    width: 100%;
  }
  .toolbar-amount {
    display: none;
  }
  #layer-product-list {
    .toolbar.toolbar-products,
    .sorter {
      display: none;
    }
  }
}
