.checkout-cart-index {
  .cart-container {
    @include breakpoint($tablet) {
      display: grid;
    }
    @include breakpoint($desktop) {
      display: block;
    }
    .cart-summary {
      // sass-lint:disable-all
      top: 0 !important; // overwrite inline
      // sass-lint:enable-all
      .multicheckout {
        span {
          font-size: 0;

          &::before {
            content: "Checkout With Multiple Addresses";
            font-size: 1.4rem;
            display: block;
            margin: 0 0 -10px;
          }
        }
      }
      @include breakpoint($tablet) {
        width: 100%;
      }
      @include breakpoint($desktop) {
        width: 25%;
      }
    }
    .form-cart {
      @include breakpoint($tablet) {
        width: 100%;
      }
      @include breakpoint($desktop) {
        width: 75%;
      }
    }
  }
}
.cart-container {
  .cart-discount {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 0 0 15px;

    @include breakpoint($desktop) {
      width: 75%;
    }

    .title {
      text-align: right;
      border: 0;
    }

    .content {
      padding-right: 0;
    }

    @include breakpoint(max-width $tablet) {
      .block > .title {
        padding-right: 20px;

        &::after {
          position: absolute;
          right: 0;
          top: 12px;
        }
      }
    }
  }

  .form-cart .actions.main {
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;

    @include breakpoint($tablet) {
      padding-right: 0;
    }
  }
}

.cart.table-wrapper .item .col.item {
  @include breakpoint($tablet) {
    padding-bottom: 5px;
  }
}

.cart-totals tbody tr:last-child td {
  padding-bottom: 10px;
}

.cart.table-wrapper .cart thead tr th.col {
  padding-top: 12px;
}

.block.crosssell {
  margin: 0;
}

.theme-pearl.page-layout-1column .products.products-grid,
.owl-carousel-products-crosssell {
  // sass-lint:disable-all
  padding-top: 0 !important;
  margin-top: 0 !important;
  // sass-lint:enable-all
}

.checkout-cart-index {
  .products-crosssell .actions-primary .stock.unavailable span {
    display: block;
    margin: 17px 0 0;
  }
}

.cart.item.message.notice {
  text-align: left;
  width: 344px;
}

.message.notice > *:first-child::before {
  content: "\e60e";
}

.col.item {
  .product-item-details {
    text-align: left;
  }
}
