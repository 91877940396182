.page-wrapper .page-header .panel.header {
  // sass-lint:disable-all
  max-width: 1400px !important;
  // sass-lint:enable-all
  padding: 10px 15px;
  margin: 0 auto;
}

.page-wrapper .page-header .panel.wrapper {
  @media screen and (max-width: 768px) {
    // sass-lint:disable-all
    display: none !important;
    // sass-lint:enable-all
  }
  @include breakpoint($tablet) {
    display: block;
  }
}

.page-header .header-global-promo .global-notification-wrapper a.close-global-notification {
  // sass-lint:disable-all
  display: none !important;
  // sass-lint:enable-all
}

.header-global-promo ul li {
  // sass-lint:disable-all
  flex-direction: row !important;
  align-items: center !important;
  // sass-lint:disable-all
}

.compare.wrapper {
  display: none;
}

.page-wrapper .page-header .header-global-promo .global-notification-wrapper {
  padding: 4px;
}

.page-wrapper div.page-header {
  &.sticky-header {
    .block.block-search {
      left: 30px;
    }
  }

  .block.block-search {
    @include breakpoint($tablet) {
      width: 320px;
      float: none;
      margin: 0 auto;
      display: flex;
      position: relative;
      left: -30px;
    }

    @include breakpoint($desktop) {
      width: 390px;
    }

    .block-content {
      width: 100%;
    }

    .action.search {
      &::before {
        line-height: 42px;
      }
    }

    .input-text {
      border: 1px solid #ccc;
      width: 100%;
      height: 42px;
      font-size: 14px;

      // sass-lint:disable-all
      &::-webkit-input-placeholder {
        color: #808080 !important; // overwrite important
        opacity: 1;
      }

      &::-moz-placeholder {
        color: #808080 !important;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: #808080 !important;
        opacity: 1;
      }

      &:-moz-placeholder {
        color: #808080 !important;
        opacity: 1;
      }
      // sass-lint:enable-all
    }
  }

  .content {
    .header_right {
      margin-top: 9px;
    }

    .field.search label::before {
      // sass-lint:disable-all
      margin-top: 4px !important; // overwrite important
      // sass-lint:enable-all
    }
  }
}

.header-phone {
  background: url("../images/icons/phone.svg") no-repeat center;
  font-size: 0;
  padding: 2px 0 1px 14px;
  margin: 0 1px 0 10px;
  width: 24px;
  height: 21px;
  float: left;

  @include breakpoint($tablet) {
    font-size: 12px;
    background-position: center left;
    width: auto;
    height: auto;
    float: none;
    margin: 0 10px;
    position: relative;
    top: -1px;
  }
}

:root .theme-pearl .page-wrapper .page-header {
  .logo {
    @include breakpoint($tablet) {
      height: auto;
      max-height: 65px;
      margin: -10px 0 0;
    }
  }

  &.sticky-header {
    .logo {
      margin: 10px 0 0;
    }
  }
}

.breadcrumbs {
  // sass-lint:disable-all
  background-color: #ffffff !important;
  // sass-lint:enable-all
}

.megamenu.level1 {
  a {
    // sass-lint:disable-all
    color: #000000 !important;
    // sass-lint:enable-all
    span {
      // sass-lint:disable-all
      font-size: 14px !important;
      border-bottom: 0 !important;
      // sass-lint:enable-all
      font-weight: bold;
    }
  }
}

.megamenu.level2 {
  a {
    // sass-lint:disable-all
    color: #575757 !important;
    // sass-lint:enable-all
    span {
      // sass-lint:disable-all
      font-size: 14px !important;
      border-bottom: 0 !important;
      // sass-lint:enable-all
      font-weight: normal;
      text-transform: capitalize;
    }
  }
}

.navigation .level0.parent>.level-top {
  padding-right: 0;
}

@include breakpoint($desktop) {
  :root .page-header-v3 .navigation .level0 > .level-top,
  :root .page-header-v4 .navigation .level0 > .level-top {
    width: 150px;
    line-height: 1.4;
  }

  .navigation .level0 {
    display: table-cell;
    vertical-align: middle;
  }

  .navigation .megamenu.level-top-fullwidth .level0.fullwidth .fullwidth-wrapper {
    border-top: solid 1px #dddddd;
  }
}

// sass-lint:disable-all
@media only screen and (max-width: 767px) {
  .header-global-promo .global-notification-wrapper .btn-bar #buttons #prev {
    left: 3% !important;
  }
  .header-global-promo .global-notification-wrapper .btn-bar .icon-angle-right {
    right: -11% !important;
  }
  .wpx-sub {
    font-size: 11px !important;
  }
}
@media (max-width: 768px) {
  :root .theme-pearl .page-wrapper .page-header .desktop-logo {
    display: unset;
  }
}
// sass-lint:enable-all

.page-header .header .minicart-wrapper {
  .product-item-details {
    text-align: left;
    position: relative;
  }
  .product.actions {
    position: absolute;
    right: 0;
    top: 10px;
  }
}
