@charset "UTF-8";
.hide {
  display: none; }

.mob-show {
  display: block; }
  @media (min-width: 753px) {
    .mob-show {
      display: none; } }

.tablet-show {
  display: none; }
  @media (min-width: 753px) {
    .tablet-show {
      display: block; } }
  @media (min-width: 992px) {
    .tablet-show {
      display: none; } }

.desktop-show {
  display: none; }
  @media (min-width: 992px) {
    .desktop-show {
      display: block; } }

.theme-pearl.store-view-default .product-info-main h1 {
  line-height: 1.3; }
  @media (min-width: 753px) {
    .theme-pearl.store-view-default .product-info-main h1 {
      font-size: 24px; } }
  @media (min-width: 992px) {
    .theme-pearl.store-view-default .product-info-main h1 {
      font-size: 30px; } }

.page-wrapper .page-header .panel.header {
  max-width: 1400px !important;
  padding: 10px 15px;
  margin: 0 auto; }

@media screen and (max-width: 768px) {
  .page-wrapper .page-header .panel.wrapper {
    display: none !important; } }

@media (min-width: 753px) {
  .page-wrapper .page-header .panel.wrapper {
    display: block; } }

.page-header .header-global-promo .global-notification-wrapper a.close-global-notification {
  display: none !important; }

.header-global-promo ul li {
  flex-direction: row !important;
  align-items: center !important; }

.compare.wrapper {
  display: none; }

.page-wrapper .page-header .header-global-promo .global-notification-wrapper {
  padding: 4px; }

.page-wrapper div.page-header.sticky-header .block.block-search {
  left: 30px; }

@media (min-width: 753px) {
  .page-wrapper div.page-header .block.block-search {
    width: 320px;
    float: none;
    margin: 0 auto;
    display: flex;
    position: relative;
    left: -30px; } }

@media (min-width: 992px) {
  .page-wrapper div.page-header .block.block-search {
    width: 390px; } }

.page-wrapper div.page-header .block.block-search .block-content {
  width: 100%; }

.page-wrapper div.page-header .block.block-search .action.search::before {
  line-height: 42px; }

.page-wrapper div.page-header .block.block-search .input-text {
  border: 1px solid #ccc;
  width: 100%;
  height: 42px;
  font-size: 14px; }
  .page-wrapper div.page-header .block.block-search .input-text::-webkit-input-placeholder {
    color: #808080 !important;
    opacity: 1; }
  .page-wrapper div.page-header .block.block-search .input-text::-moz-placeholder {
    color: #808080 !important;
    opacity: 1; }
  .page-wrapper div.page-header .block.block-search .input-text:-ms-input-placeholder {
    color: #808080 !important;
    opacity: 1; }
  .page-wrapper div.page-header .block.block-search .input-text:-moz-placeholder {
    color: #808080 !important;
    opacity: 1; }

.page-wrapper div.page-header .content .header_right {
  margin-top: 9px; }

.page-wrapper div.page-header .content .field.search label::before {
  margin-top: 4px !important; }

.header-phone {
  background: url("../images/icons/phone.svg") no-repeat center;
  font-size: 0;
  padding: 2px 0 1px 14px;
  margin: 0 1px 0 10px;
  width: 24px;
  height: 21px;
  float: left; }
  @media (min-width: 753px) {
    .header-phone {
      font-size: 12px;
      background-position: center left;
      width: auto;
      height: auto;
      float: none;
      margin: 0 10px;
      position: relative;
      top: -1px; } }

@media (min-width: 753px) {
  :root .theme-pearl .page-wrapper .page-header .logo {
    height: auto;
    max-height: 65px;
    margin: -10px 0 0; } }

:root .theme-pearl .page-wrapper .page-header.sticky-header .logo {
  margin: 10px 0 0; }

.breadcrumbs {
  background-color: #ffffff !important; }

.megamenu.level1 a {
  color: #000000 !important; }
  .megamenu.level1 a span {
    font-size: 14px !important;
    border-bottom: 0 !important;
    font-weight: bold; }

.megamenu.level2 a {
  color: #575757 !important; }
  .megamenu.level2 a span {
    font-size: 14px !important;
    border-bottom: 0 !important;
    font-weight: normal;
    text-transform: capitalize; }

.navigation .level0.parent > .level-top {
  padding-right: 0; }

@media (min-width: 992px) {
  :root .page-header-v3 .navigation .level0 > .level-top,
  :root .page-header-v4 .navigation .level0 > .level-top {
    width: 150px;
    line-height: 1.4; }
  .navigation .level0 {
    display: table-cell;
    vertical-align: middle; }
  .navigation .megamenu.level-top-fullwidth .level0.fullwidth .fullwidth-wrapper {
    border-top: solid 1px #dddddd; } }

@media only screen and (max-width: 767px) {
  .header-global-promo .global-notification-wrapper .btn-bar #buttons #prev {
    left: 3% !important; }
  .header-global-promo .global-notification-wrapper .btn-bar .icon-angle-right {
    right: -11% !important; }
  .wpx-sub {
    font-size: 11px !important; } }

@media (max-width: 768px) {
  :root .theme-pearl .page-wrapper .page-header .desktop-logo {
    display: unset; } }

.page-header .header .minicart-wrapper .product-item-details {
  text-align: left;
  position: relative; }

.page-header .header .minicart-wrapper .product.actions {
  position: absolute;
  right: 0;
  top: 10px; }

.checkout-index-index .modal-inner-wrap footer.modal-footer button.action {
  margin-top: 0; }
  .checkout-index-index .modal-inner-wrap footer.modal-footer button.action span {
    display: block; }

.checkout-container .payment-option-content .form-discount .actions-toolbar .primary .action {
  border: 0;
  height: 40px;
  background: #000;
  color: #fff;
  margin: 0 0 0 5px;
  box-shadow: none !important; }
  .checkout-container .payment-option-content .form-discount .actions-toolbar .primary .action:hover, .checkout-container .payment-option-content .form-discount .actions-toolbar .primary .action:focus, .checkout-container .payment-option-content .form-discount .actions-toolbar .primary .action:active {
    background: #000;
    box-shadow: none;
    border: 0; }
  .checkout-container .payment-option-content .form-discount .actions-toolbar .primary .action span {
    color: #fff; }

.checkout-container .payment-option-content .form-discount .input-text {
  height: 40px; }

.opc-block-summary .content.minicart-items {
  display: block !important; }

.opc-block-summary .items-in-cart.active > .title::after,
.opc-block-summary .items-in-cart > .title::after {
  display: none !important; }

:root .theme-pearl.checkout-index-index .page-header .logo {
  margin: 0 !important; }

.table-checkout-shipping-method thead {
  display: none; }

.checkout-index-index .page-wrapper .page-main {
  padding-top: 0; }

.table-checkout-shipping-method tbody td:first-child {
  width: unset; }

[data-testid="deko-wallet-overlay"] {
  z-index: 2147483647; }

.theme-pearl .grey-background .row {
  max-width: 1170px !important; }

.page-wrapper .page-header .header-global-promo .global-notification-wrapper {
  padding: 5px 0; }
  .page-wrapper .page-header .header-global-promo .global-notification-wrapper a {
    text-decoration: none; }

.header-global-promo #slides ul li .quoteContainer .wpx-link {
  text-decoration: none !important; }

.custom-slider .owl-carousel .owl-stage-outer {
  padding: 0 !important; }

.page-header .header-global-promo .global-notification-wrapper a.close-global-notification {
  top: 6px; }

.sections.nav-sections-3.nav-sections.too-wide {
  width: 100%;
  text-align: center !important;
  margin: 10px 0 0; }
  .sections.nav-sections-3.nav-sections.too-wide .fullwidth-wrapper {
    text-align: left !important; }

@media (min-width: 992px) {
  .navigation .megamenu.level-top-fullwidth .level0.fullwidth .fullwidth-wrapper .fullwidth-wrapper-inner .columns-group {
    width: 20% !important; } }

.navigation .megamenu.level-top-fullwidth .submenu [data-has-children] > a span:last-child,
.navigation .megamenu.level-top-sectioned .submenu [data-has-children] > a span:last-child,
.navigation .megamenu.level-top-boxed .submenu [data-has-children] > a span:last-child {
  display: block;
  line-height: 1.3; }

.most-popular-block {
  max-width: 1200px;
  padding: 0;
  margin: 0 auto; }
  .most-popular-block ul {
    margin: 0 0 40px;
    padding: 0;
    display: flex;
    list-style: none;
    flex-wrap: wrap; }
    @media (min-width: 753px) {
      .most-popular-block ul {
        flex-wrap: nowrap; } }
    .most-popular-block ul li {
      width: 50%;
      margin: 0 0 20px;
      padding: 0 10px; }
      .most-popular-block ul li h5 {
        font-family: "Source Code Pro", sans-serif;
        font-size: 15px; }
      .most-popular-block ul li:last-child {
        display: none; }
      @media (min-width: 753px) {
        .most-popular-block ul li {
          width: 20%; }
          .most-popular-block ul li:last-child {
            display: block; } }
      .most-popular-block ul li:first-child {
        margin-left: 0; }
  .most-popular-block img {
    width: 100%; }

@media (min-width: 1024px), print {
  .content-v5 .box-content .page-small-width h4 {
    margin-top: 0; }
  .content-v5 .content-opengreen-light h4 {
    margin-top: 100px !important; } }

.theme-pearl .grey-background {
  padding: 50px 0 20px; }

@media (min-width: 753px) {
  .checkout-cart-index .cart-container {
    display: grid; } }

@media (min-width: 992px) {
  .checkout-cart-index .cart-container {
    display: block; } }

.checkout-cart-index .cart-container .cart-summary {
  top: 0 !important; }
  .checkout-cart-index .cart-container .cart-summary .multicheckout span {
    font-size: 0; }
    .checkout-cart-index .cart-container .cart-summary .multicheckout span::before {
      content: "Checkout With Multiple Addresses";
      font-size: 1.4rem;
      display: block;
      margin: 0 0 -10px; }
  @media (min-width: 753px) {
    .checkout-cart-index .cart-container .cart-summary {
      width: 100%; } }
  @media (min-width: 992px) {
    .checkout-cart-index .cart-container .cart-summary {
      width: 25%; } }

@media (min-width: 753px) {
  .checkout-cart-index .cart-container .form-cart {
    width: 100%; } }

@media (min-width: 992px) {
  .checkout-cart-index .cart-container .form-cart {
    width: 75%; } }

.cart-container .cart-discount {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0 0 15px; }
  @media (min-width: 992px) {
    .cart-container .cart-discount {
      width: 75%; } }
  .cart-container .cart-discount .title {
    text-align: right;
    border: 0; }
  .cart-container .cart-discount .content {
    padding-right: 0; }
  @media (max-width: 753px) {
    .cart-container .cart-discount .block > .title {
      padding-right: 20px; }
      .cart-container .cart-discount .block > .title::after {
        position: absolute;
        right: 0;
        top: 12px; } }

.cart-container .form-cart .actions.main {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px; }
  @media (min-width: 753px) {
    .cart-container .form-cart .actions.main {
      padding-right: 0; } }

@media (min-width: 753px) {
  .cart.table-wrapper .item .col.item {
    padding-bottom: 5px; } }

.cart-totals tbody tr:last-child td {
  padding-bottom: 10px; }

.cart.table-wrapper .cart thead tr th.col {
  padding-top: 12px; }

.block.crosssell {
  margin: 0; }

.theme-pearl.page-layout-1column .products.products-grid,
.owl-carousel-products-crosssell {
  padding-top: 0 !important;
  margin-top: 0 !important; }

.checkout-cart-index .products-crosssell .actions-primary .stock.unavailable span {
  display: block;
  margin: 17px 0 0; }

.cart.item.message.notice {
  text-align: left;
  width: 344px; }

.message.notice > *:first-child::before {
  content: "\e60e"; }

.col.item .product-item-details {
  text-align: left; }

@media (min-width: 992px) {
  .cms-page-view img {
    margin-left: auto;
    margin-right: auto;
    display: block; } }

.cms-page-view .main {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto; }

.cms-page-view .page-main {
  margin-top: 32px; }

@media (min-width: 992px) {
  .cms-page-view .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 36px; } }

@media (min-width: 992px) {
  .cms-page-view .grid-container img {
    height: 100%;
    object-fit: cover; } }

.cms-page-view .grid-container + h2 {
  margin-top: 48px; }

.cms-page-view .cms-text-content p:last-child {
  margin-bottom: 0; }

.cms-page-view .gr-divider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 48px 0;
  line-height: 14px; }
  @media (min-width: 992px) {
    .cms-page-view .gr-divider {
      margin: 64px 0; } }
  .cms-page-view .gr-divider::after {
    width: calc(100% - 29px);
    height: 1px;
    background-color: #EEEEEE;
    margin: 6px 0;
    content: "";
    display: inline-block; }
  .cms-page-view .gr-divider::before {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: #E5E5E5;
    content: "";
    display: inline-block; }

.cms-page-view .center-align {
  text-align: center;
  padding: 0 16px; }

.cms-page-view .page-wrapper .page-main {
  padding: 0 16px 58px 16px; }
  @media (min-width: 753px) {
    .cms-page-view .page-wrapper .page-main {
      padding: 0 24px 90px 24px; } }
  @media (min-width: 992px) {
    .cms-page-view .page-wrapper .page-main {
      padding: 0 86px 90px 86px; } }
  .cms-page-view .page-wrapper .page-main .page-title-wrapper {
    margin: 40px 0 0 0;
    text-align: center; }
    .cms-page-view .page-wrapper .page-main .page-title-wrapper .page-title {
      font-size: 32px;
      letter-spacing: 0;
      line-height: 33px;
      margin-bottom: 0; }
  .cms-page-view .page-wrapper .page-main p {
    margin: 20px 0; }
  .cms-page-view .page-wrapper .page-main p.bold-first {
    font-weight: 600;
    text-align: center;
    color: #303030;
    margin-top: 0;
    margin-bottom: 24px; }
  .cms-page-view .page-wrapper .page-main h2 {
    margin: 0 0 20px 0;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px; }
  .cms-page-view .page-wrapper .page-main h3 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
    margin-top: 30px; }
    .cms-page-view .page-wrapper .page-main h3:first-child {
      margin-top: 0; }
  .cms-page-view .page-wrapper .page-main p + h2 {
    margin-top: 48px; }
  .cms-page-view .page-wrapper .page-main a {
    color: inherit;
    text-decoration: underline; }
  .cms-page-view .page-wrapper .page-main ul {
    list-style: none;
    padding-left: 28px; }
  .cms-page-view .page-wrapper .page-main li {
    margin-bottom: 24px; }
  .cms-page-view .page-wrapper .page-main li::before {
    content: "•";
    display: inline-block;
    height: 20px;
    width: 18px;
    background-size: 8px;
    background-repeat: no-repeat;
    margin-left: -22px;
    background-position: center; }

.cms-page-view h4 {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px; }

.cms-page-view .column.main {
  padding-top: 24px; }
  @media (min-width: 992px) {
    .cms-page-view .column.main {
      padding-top: 32px; } }

.cms-page-view .side-tabs ul.tab-nav {
  padding: 0;
  list-style: none;
  width: 100%;
  margin: 0 0 20px;
  float: none; }
  @media (min-width: 753px) {
    .cms-page-view .side-tabs ul.tab-nav {
      margin: 0;
      width: 200px;
      float: left; } }
  .cms-page-view .side-tabs ul.tab-nav li::before {
    display: none; }
  .cms-page-view .side-tabs ul.tab-nav li.ui-state-active a {
    background-color: #339966; }
  .cms-page-view .side-tabs ul.tab-nav .ui-tabs-anchor {
    line-height: 1.5;
    padding: 12px 15px; }

.cms-page-view .side-tabs .tab-container {
  padding-left: 0; }
  @media (min-width: 753px) {
    .cms-page-view .side-tabs .tab-container {
      padding-left: 20px; } }

.cms-page-view .ui-tabs-panel.tab-content > h4 {
  margin-top: 0; }

.cms-page-block .cms-sidebar ul {
  list-style: none;
  margin: 0;
  padding: 0; }
  .cms-page-block .cms-sidebar ul li {
    list-style: none;
    padding: 0 !important;
    margin: 0 0 5px !important; }
    .cms-page-block .cms-sidebar ul li a {
      background: #F5F5F5;
      padding: 12px 15px;
      border-radius: 2px;
      display: block;
      color: #444;
      border: 1px solid #DDD; }
      .cms-page-block .cms-sidebar ul li a.active {
        color: #fff;
        background: #396; }
    .cms-page-block .cms-sidebar ul li::before {
      display: none !important; }

.contact-index-index .column .contact-container {
  margin: 0; }

.contact-index-index .form-area .page-title::before {
  position: relative;
  content: "How Can We Help You?";
  font-size: 20px;
  padding: 17px 0;
  text-transform: uppercase;
  font-weight: 400;
  color: #303030;
  margin: 2rem 0;
  line-height: 1.1;
  display: block; }

.contact-index-index .form-area h1 {
  display: none; }

.socials {
  display: flex;
  justify-content: center;
  margin-bottom: 80px; }
  @media (min-width: 992px) {
    .socials {
      margin: 32px 0 107px 0; } }
  .socials .icon {
    height: 32px;
    width: 32px;
    margin: 0 8px;
    background-repeat: no-repeat;
    background-position: center; }

.quality-products img {
  margin-top: 32px; }
  @media (min-width: 992px) {
    .quality-products img {
      max-width: 616px; } }

.facebook-icon {
  background-image: url(../images/icons/icon-facebook.svg); }

.insta-icon {
  background-image: url(../images/icons/icon-instagram.svg); }

.twitter-icon {
  background-image: url(../images/icons/icon-twitter.svg); }

.youtube-icon {
  background-image: url(../images/icons/icon-youtube.svg); }

@media (min-width: 992px) {
  .main .about-us-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 32px;
    grid-column-gap: 36px; } }

@media (min-width: 992px) {
  .main .about-us-grid p {
    margin-top: 0; } }

@media (min-width: 992px) {
  .grid-area-1 {
    order: 1; } }

@media (min-width: 992px) {
  .grid-area-2 {
    order: 2; } }

@media (min-width: 992px) {
  .grid-area-3 {
    order: 4; } }

@media (min-width: 992px) {
  .grid-area-4 {
    order: 3; } }

.cms-gardening-tips .columns img {
  min-height: 250px;
  object-fit: cover; }

.cms-shop-by-brand.cms-page-view .columns ul {
  padding-left: 0; }
  .cms-shop-by-brand.cms-page-view .columns ul li::before {
    content: "";
    height: 100%;
    margin-left: 0; }

.shop-by-brands {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap; }
  .shop-by-brands a {
    display: block;
    margin: 25px;
    flex-basis: 35%; }
    @media (min-width: 992px) {
      .shop-by-brands a {
        flex-basis: unset; } }

.clients-grid li::before {
  display: none !important; }

button.action.alert {
  background-color: #FF4314 !important;
  border-color: #FF4314 !important;
  width: 100%; }
  button.action.alert:hover {
    border-color: #FF4314 !important;
    color: #FF4314 !important;
    background: #ffffff; }

#widget_frame {
  width: 100%; }

.theme-pearl .product-info-main .product.attribute.overview {
  width: 100%; }

.product-info-main .product .alert {
  margin: 0;
  padding: 15px 0; }

.delivery-time,
.out-of-stock-lead {
  padding: 15px;
  border: solid 1px #ccc;
  margin: 0 0 15px; }

.out-of-stock-lead {
  background: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24; }

.incl-vat-label {
  display: none; }

.catalog-product-view .incl-vat-label {
  display: inline-block;
  font-size: 14px; }

.product-brand .brand-image img {
  width: 80px;
  height: auto !important; }

.product-brand .brand-name {
  display: none; }

.product-brand-logos-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .product-brand-logos-wrapper .engine-logo a,
  .product-brand-logos-wrapper .promo-image-container a,
  .product-brand-logos-wrapper .brand-logo a {
    display: block; }
  .product-brand-logos-wrapper .engine-logo img,
  .product-brand-logos-wrapper .promo-image-container img,
  .product-brand-logos-wrapper .brand-logo img {
    max-height: 68px;
    width: auto; }

.theme-pearl.store-view-default .product-info-main h1 {
  font-size: 26px; }

.product-info-main .product.attribute.sku .type::after {
  display: none; }

.product-options-bottom .price-box .old-price,
.product-info-price .price-box .old-price,
.product-options-bottom .price-box .price-container,
.product-info-price .price-box .price-container,
.product-info-main .product-info-price .old-price .price-wrapper .price {
  font-size: 14px; }

.theme-pearl.catalog-product-view #maincontent .product-info-main .product-info-price .price-box {
  width: 70%; }

.theme-pearl.catalog-product-view .product-info-main .product-info-stock-sku {
  padding-left: 0; }

.special-price .price {
  color: #FF0A12 !important; }

.filter-label {
  margin: 0 0 0 15px; }

.products-grid .product-item .product-item-name a {
  font-size: 14px !important;
  display: block;
  word-break: keep-all;
  hyphens: initial;
  min-height: 75px; }
  @media (min-width: 992px) {
    .products-grid .product-item .product-item-name a {
      min-height: 60px; } }

.product-options-bottom .price-box,
.product-info-price .price-box {
  padding: 0; }

.store-view-default .rrp {
  margin: 0 0 15px;
  min-height: 18px; }

.catalog-product-view .product.info.detailed .additional-attributes {
  width: 100%; }

.catalog-product-view .table > thead > tr > th,
.catalog-product-view .table > tbody > tr > th,
.catalog-product-view .table > tfoot > tr > th,
.catalog-product-view .table > thead > tr > td,
.catalog-product-view .table > tbody > tr > td,
.catalog-product-view .table > tfoot > tr > td {
  border-bottom: solid 1px #dddddd !important; }

.catalog-product-view .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr {
  display: table-row; }
  .catalog-product-view .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr:hover {
    background: #F6F6F7; }

.catalog-product-view .abs-no-display-s,
.catalog-product-view .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison).additional-attributes tbody th,
.catalog-product-view .order-details-items .table-order-items .options-label,
.catalog-product-view .opc-wrapper .shipping-address-item.selected-item .action-select-shipping-item {
  display: table-cell;
  width: 50%;
  padding: 10px;
  background-color: #F6F6F7; }
  @media (min-width: 992px) {
    .catalog-product-view .abs-no-display-s,
    .catalog-product-view .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison).additional-attributes tbody th,
    .catalog-product-view .order-details-items .table-order-items .options-label,
    .catalog-product-view .opc-wrapper .shipping-address-item.selected-item .action-select-shipping-item {
      width: 30%; } }

.catalog-product-view .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison).additional-attributes tbody td:last-child {
  display: table-cell;
  width: 50%;
  padding: 10px; }
  @media (min-width: 992px) {
    .catalog-product-view .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison).additional-attributes tbody td:last-child {
      width: 70%; } }

.catalog-product-view .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr td[data-th]::before,
.catalog-product-view .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison) > tbody > tr th[data-th]::before {
  display: none; }

tr.Promotional-Image {
  display: none !important; }

.data.item.content a {
  font-weight: bold;
  text-decoration: underline;
  color: #1D8A33; }

.catalog-product-view .column.main .products-grid.products-related .product-item .product-item-info .product-item-details .field.choice.related {
  width: 100% !important; }

.product-info-main .product.alert {
  padding: 15px 0 0; }
  .product-info-main .product.alert a::before {
    content: "\f0e0";
    font: normal normal normal 14px/1 FontAwesome; }

.catalog-category-view .category__image {
  min-height: 91px; }

.catalog-category-view .slick-slider {
  position: relative; }
  .catalog-category-view .slick-slider .slick-prev,
  .catalog-category-view .slick-slider .slick-next {
    bottom: 40px !important;
    top: unset;
    border: 0;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.22); }
    .catalog-category-view .slick-slider .slick-prev::before,
    .catalog-category-view .slick-slider .slick-next::before {
      opacity: unset; }
    .catalog-category-view .slick-slider .slick-prev:hover,
    .catalog-category-view .slick-slider .slick-next:hover {
      background-color: white;
      border: 0; }
      .catalog-category-view .slick-slider .slick-prev:hover::before,
      .catalog-category-view .slick-slider .slick-next:hover::before {
        color: #1da048; }
  .catalog-category-view .slick-slider .slick-prev {
    right: 40px;
    left: unset;
    z-index: 9; }
    .catalog-category-view .slick-slider .slick-prev::before {
      font-weight: bold;
      content: "<";
      position: relative;
      top: -5px; }
  .catalog-category-view .slick-slider .slick-next {
    right: 0; }
    .catalog-category-view .slick-slider .slick-next::before {
      font-weight: bold;
      content: ">";
      position: relative;
      top: -5px; }
  .catalog-category-view .slick-slider .slider--container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (min-width: 753px) {
      .catalog-category-view .slick-slider .slider--container {
        flex-direction: row;
        margin: 0 0 60px; } }
  .catalog-category-view .slick-slider .slider--content {
    padding: 30px;
    color: #ffffff; }
    @media (min-width: 753px) {
      .catalog-category-view .slick-slider .slider--content {
        width: 50%;
        height: 296px; } }
  .catalog-category-view .slick-slider .slider--title {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    margin: 0 0 10px;
    text-transform: none;
    color: #ffffff; }
  .catalog-category-view .slick-slider .slider--text {
    margin: 0 0 10px; }
  .catalog-category-view .slick-slider .slider--button {
    border: solid 1px #ffffff;
    color: #ffffff;
    padding: 8px 3px;
    height: 40px;
    min-width: 169px;
    text-align: center;
    margin: 15px 0 0;
    display: inline-block;
    width: 100%; }
    @media (min-width: 992px) {
      .catalog-category-view .slick-slider .slider--button {
        width: inherit;
        padding: 8px 10px; } }
    .catalog-category-view .slick-slider .slider--button:hover {
      background: #ffffff;
      color: #1DA048; }
  .catalog-category-view .slick-slider .slider--media {
    width: 100%; }
    .catalog-category-view .slick-slider .slider--media-image {
      width: 100%;
      overflow: hidden; }

.catalog-category-view .category-cms h3 {
  font-size: 20px; }

.catalog-category-view .rrp {
  margin: 15px 0 0;
  text-align: center; }

.product-item-details {
  text-align: left; }

.actions-primary .stock.unavailable span {
  background-color: #FF4314 !important;
  border: solid 1px #FF4314 !important;
  width: 100%;
  color: #ffffff;
  font-size: 18px;
  padding: 7.5px 15px;
  position: relative;
  top: -17px; }
  .actions-primary .stock.unavailable span:hover {
    border: solid 1px #FF4314 !important;
    background: #ffffff !important;
    color: #FF4314 !important; }

.block-viewed-products-grid .actions-primary .stock.unavailable span {
  display: block; }

.category-description p {
  margin: 0 0 30px !important; }

.catalog-category-view .actions-primary .stock.unavailable span {
  top: unset !important;
  display: inline-block; }

.column.main .products-grid .product-item .product-item-info .product-item-details .product-item-inner .product-item-actions {
  text-align: center; }

.block.filter dt {
  display: none; }

.block.filter .item {
  text-transform: uppercase;
  font-size: 12px;
  position: relative;
  border-bottom: solid 1px #c8c8c8;
  padding: 0 0 10px; }
  .block.filter .item a {
    display: block; }
    .block.filter .item a::before {
      margin: 3px 5px 0 0 !important; }
  .block.filter .item .count {
    display: none;
    position: absolute;
    top: 0;
    right: 0; }

.block.filter .items--child {
  display: none;
  text-transform: none !important; }

.brand-engine-info-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 16px 0 0; }

.theme-pearl.page-products .columns .column .products-grid .product-item {
  margin-bottom: 0; }

.filter .filter-current {
  margin: 45px 0 0;
  border: 0; }
  @media (min-width: 992px) {
    .filter .filter-current {
      margin: 0; } }

.products-grid .product-item .product-item-details .price-box .old-price {
  display: block;
  margin-left: 0; }

.shop-by-brand {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }
  .shop-by-brand a {
    padding: 15px; }

button.out-of-stock {
  background-color: #ff4314 !important;
  border: solid 1px #ff4314 !important; }
  button.out-of-stock:hover {
    background-color: #fff !important;
    color: #ff4314 !important; }
    button.out-of-stock:hover span {
      color: #ff4314 !important; }

.page-products .products-grid .product-item .product-item-info:hover .weltpixel-quickview.weltpixel_quickview_button_v2 {
  top: 0; }

.category-view.mobile {
  display: none; }

.theme-pearl.page-products .sidebar-main .sorter,
.page-with-filter .columns .sidebar-main .sorter {
  display: none; }

body.filter-active .page-wrapper {
  margin-top: 0 !important; }

@media only screen and (max-width: 767px) {
  .filter-title strong {
    top: 10px; }
  .filter.active .filter-title strong::after {
    color: #1D8A33; }
  .page-with-filter .columns .sidebar-main {
    order: 1;
    margin-bottom: 0 !important;
    position: relative;
    padding-top: 50px; }
    .page-with-filter .columns .sidebar-main .sorter {
      display: block;
      position: absolute !important;
      top: 10px; }
    .page-with-filter .columns .sidebar-main .filter .filter-current {
      margin-top: 10px; }
  .page-with-filter .columns .column.main {
    order: 2; }
  .page-with-filter .category-view:not(.mobile) {
    display: none; }
  .page-with-filter .category-view.mobile {
    display: block;
    width: 100%; }
  .toolbar-amount {
    display: none; }
  #layer-product-list .toolbar.toolbar-products,
  #layer-product-list .sorter {
    display: none; } }

.page-products .columns {
  padding-top: 0; }
  @media (min-width: 992px) {
    .page-products .columns {
      padding-top: 60px; } }

.page-products .sorter {
  position: unset !important; }

.catalog-category-view .page-title {
  margin: 30px 0 40px;
  text-transform: unset; }

.catalog-category-view .category-cms h3 {
  text-transform: unset;
  font-size: 20px;
  border-bottom: solid 1px #ccc;
  padding: 0 0 30px;
  margin: 0 0 30px;
  position: relative; }
  .catalog-category-view .category-cms h3::before {
    position: absolute;
    content: "";
    width: 15px;
    height: 15px;
    background: #ccc;
    bottom: -8px;
    border-radius: 100%;
    outline: 22px solid #fff; }

.catalog-category-view .category-show-more {
  display: none; }

.catalog-category-view .category-description {
  margin: 60px 0 0;
  padding: 60px 0 0;
  border-top: solid 1px #ccc;
  position: relative; }
  .catalog-category-view .category-description::before {
    position: absolute;
    content: "";
    width: 15px;
    height: 15px;
    background: #ccc;
    top: -8px;
    border-radius: 100%;
    outline: 25px solid #fff; }

.catalog-category-view .subcategory__wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px 0 0; }

.catalog-category-view .category__wrapper {
  width: calc(100% / 2);
  padding: 0 20px;
  margin: 0 0 20px; }
  @media (min-width: 992px) {
    .catalog-category-view .category__wrapper {
      width: calc(100% / 4); } }
  .catalog-category-view .category__wrapper:nth-child(1), .catalog-category-view .category__wrapper:nth-child(2) {
    width: 100%; }
    @media (min-width: 753px) {
      .catalog-category-view .category__wrapper:nth-child(1), .catalog-category-view .category__wrapper:nth-child(2) {
        width: 50%; } }
    .catalog-category-view .category__wrapper:nth-child(1) .category__description, .catalog-category-view .category__wrapper:nth-child(2) .category__description {
      display: block; }

.catalog-category-view .category__title {
  text-transform: uppercase;
  font-size: 20px;
  margin: 0 0 15px;
  text-decoration: underline; }

.catalog-category-view .category__description {
  display: none; }
  @media (min-width: 753px) {
    .catalog-category-view .category__description {
      display: block; } }

.category-content-categories__heading.title {
  font-size: 20px;
  text-transform: none; }

.category-content-categories ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 -1% -40px;
  list-style: none; }
  .category-content-categories ul li {
    margin: 0 1% 30px;
    width: 23%; }
    .category-content-categories ul li:first-child, .category-content-categories ul li:nth-child(2) {
      width: 48%; }
    .category-content-categories ul li h2,
    .category-content-categories ul li h3 {
      font-size: 20px;
      margin: 18px 0 8px;
      text-decoration: underline; }
  .category-content-categories ul img,
  .category-content-categories ul a {
    display: block; }

.category-content-categories .divider {
  margin: 25px 0 30px; }

.theme-pearl.store-view-default .category-description h2 {
  font-size: 20px;
  text-transform: none; }

.products-grid .product-item-name a {
  font-weight: bold;
  font-size: 14px !important; }

.brand-engine-info-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .brand-engine-info-wrapper .brand-wrapper {
    display: inline-block;
    width: 40%; }
  .brand-engine-info-wrapper .engine-wrapper {
    display: inline-block;
    width: 40%; }
  .brand-engine-info-wrapper .promo-image-container {
    display: inline-block;
    width: 40%; }
  .brand-engine-info-wrapper img {
    max-width: 90%; }

footer.page-footer {
  padding: 10px 0 20px !important; }

.inline-social {
  display: inline-block; }
  @media (min-width: 992px) {
    .inline-social {
      padding: 15px 15px 0 0 !important; } }
  .inline-social img {
    height: 26px;
    width: auto; }

.page-wrapper .page-footer .footer.content .footer-v1-content {
  display: block;
  max-width: 1200px;
  margin: 0 auto; }

@media (min-width: 992px) {
  .trustpilot-box {
    padding: 45px 0 0 0; } }

.page-wrapper .page-footer .footer.content .footer-v1-content .mini-logo {
  padding-top: 10px; }

.page-wrapper .page-footer .footer.content .footer-v1-content .footer-title {
  padding: 10px 0; }

.close-search {
  display: none; }

.mobile-search {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  padding: 40px;
  color: #ffffff; }
  .mobile-search.active {
    display: block; }
  .mobile-search a {
    color: #ffffff; }
  .mobile-search .close-search {
    display: block;
    text-align: right; }
    .mobile-search .close-search i {
      font-size: 22px; }
  .mobile-search .form.minisearch {
    display: flex; }
  .mobile-search .block-search .label {
    display: none; }
  .mobile-search .field.search {
    width: 100%; }
  .mobile-search .block-search input {
    font-size: 18px;
    left: unset;
    position: unset;
    margin: 20px 0 0;
    display: inline-block;
    background: transparent;
    color: #ffffff;
    border: solid 2px #ffffff;
    height: 44px;
    padding: 5px; }
    .mobile-search .block-search input::placeholder {
      color: #ffffff; }
  .mobile-search .block-search .action.search {
    display: none;
    background: #ffffff;
    border: 0; }
    .mobile-search .block-search .action.search span {
      color: #000000; }
  .mobile-search .customHtml {
    display: block !important; }

.aw_blog-post-view .blog-related-posts {
  display: none; }

.message.global.cookie {
  background: #ffee9c;
  border: solid 6px #d6ca8e;
  position: fixed;
  bottom: 18px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 36px);
  max-width: 1920px;
  z-index: 9999;
  padding: 12px; }
  .message.global.cookie .content {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between; }
  .message.global.cookie h3 {
    font-size: 18px;
    margin: 0 0 5px;
    font-weight: bold; }
  .message.global.cookie .actions {
    margin: 0; }
  .message.global.cookie button {
    padding: 15px; }
  .message.global.cookie a {
    text-decoration: underline; }

.cookie-overlay {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9998; }

/*
====== Zoom effect ======
*/
.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */ }

.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8); }

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out; }

.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1); }

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8; }

.mfp-zoom-in.mfp-removing .mfp-with-anim {
  transform: scale(0.8);
  opacity: 0; }

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0; }

/*
====== Newspaper effect ======
*/
.mfp-newspaper {
  /* start state */
  /* animate in */
  /* animate out */ }

.mfp-newspaper .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.5s;
  transform: scale(0) rotate(500deg); }

.mfp-newspaper.mfp-bg {
  opacity: 0;
  transition: all 0.5s; }

.mfp-newspaper.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1) rotate(0deg); }

.mfp-newspaper.mfp-ready.mfp-bg {
  opacity: 0.8; }

.mfp-newspaper.mfp-removing .mfp-with-anim {
  transform: scale(0) rotate(500deg);
  opacity: 0; }

.mfp-newspaper.mfp-removing.mfp-bg {
  opacity: 0; }

/*
====== Move-horizontal effect ======
*/
.mfp-move-horizontal {
  /* start state */
  /* animate in */
  /* animate out */ }

.mfp-move-horizontal .mfp-with-anim {
  opacity: 0;
  transition: all 0.3s;
  transform: translateX(-50px); }

.mfp-move-horizontal.mfp-bg {
  opacity: 0;
  transition: all 0.3s; }

.mfp-move-horizontal.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: translateX(0); }

.mfp-move-horizontal.mfp-ready.mfp-bg {
  opacity: 0.8; }

.mfp-move-horizontal.mfp-removing .mfp-with-anim {
  transform: translateX(50px);
  opacity: 0; }

.mfp-move-horizontal.mfp-removing.mfp-bg {
  opacity: 0; }

/*
====== Move-from-top effect ======
*/
.mfp-move-from-top {
  /* start state */
  /* animate in */
  /* animate out */ }

.mfp-move-from-top .mfp-with-anim {
  opacity: 0;
  transition: all 0.2s;
  transform: translateY(-100px); }

.mfp-move-from-top.mfp-bg {
  opacity: 0;
  transition: all 0.2s; }

.mfp-move-from-top.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: translateY(0); }

.mfp-move-from-top.mfp-ready.mfp-bg {
  opacity: 0.8; }

.mfp-move-from-top.mfp-removing .mfp-with-anim {
  transform: translateY(-50px);
  opacity: 0; }

.mfp-move-from-top.mfp-removing.mfp-bg {
  opacity: 0; }

/*
====== 3d unfold ======
*/
.mfp-3d-unfold {
  /* start state */
  /* animate in */
  /* animate out */ }

.mfp-3d-unfold .mfp-content {
  perspective: 2000px; }

.mfp-3d-unfold .mfp-with-anim {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform-style: preserve-3d;
  transform: rotateY(-60deg); }

.mfp-3d-unfold.mfp-bg {
  opacity: 0;
  transition: all 0.5s; }

.mfp-3d-unfold.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: rotateY(0deg); }

.mfp-3d-unfold.mfp-ready.mfp-bg {
  opacity: 0.8; }

.mfp-3d-unfold.mfp-removing .mfp-with-anim {
  transform: rotateY(60deg);
  opacity: 0; }

.mfp-3d-unfold.mfp-removing.mfp-bg {
  opacity: 0; }

/*
====== Zoom-out effect ======
*/
.mfp-zoom-out {
  /* start state */
  /* animate in */
  /* animate out */ }

.mfp-zoom-out .mfp-with-anim {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: scale(1.3); }

.mfp-zoom-out.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out; }

.mfp-zoom-out.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1); }

.mfp-zoom-out.mfp-ready.mfp-bg {
  opacity: 0.8; }

.mfp-zoom-out.mfp-removing .mfp-with-anim {
  transform: scale(1.3);
  opacity: 0; }

.mfp-zoom-out.mfp-removing.mfp-bg {
  opacity: 0; }

/**
  ====== Fade-zoom effect ======
 */
/* start state */
.mfp-fade-zoom .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8); }

/* animate in */
.mfp-fade-zoom.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

/* animate out */
.mfp-fade-zoom.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0; }

/* Dark overlay, start state */
.mfp-fade-zoom.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

/* animate in */
.mfp-fade-zoom.mfp-ready.mfp-bg {
  opacity: 0.8; }

/* animate out */
.mfp-fade-zoom.mfp-removing.mfp-bg {
  opacity: 0; }

/**
 ====== Fade-slide effect ======
 */
/* at start */
.mfp-fade-slide .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  -webkit-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -moz-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -ms-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  transform: translateY(-20px) perspective(600px) rotateX(10deg); }

/* animate in */
.mfp-fade-slide.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: translateY(0) perspective(600px) rotateX(0);
  -moz-transform: translateY(0) perspective(600px) rotateX(0);
  -ms-transform: translateY(0) perspective(600px) rotateX(0);
  -o-transform: translateY(0) perspective(600px) rotateX(0);
  transform: translateY(0) perspective(600px) rotateX(0); }

/* animate out */
.mfp-fade-slide.mfp-removing .zoom-anim-dialog {
  opacity: 0;
  -webkit-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -moz-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -ms-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  transform: translateY(-10px) perspective(600px) rotateX(10deg); }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline;
  vertical-align: middle;
  margin: 0 auto;
  text-align: center;
  z-index: 1045; }

.popup-content {
  background-color: #fff;
  min-height: 200px;
  min-width: 300px;
  max-width: 300px;
  display: inline-block;
  position: relative;
  padding: 20px 30px; }
  @media (min-width: 753px) {
    .popup-content {
      min-width: 500px;
      max-width: 500px; } }
  .popup-content button {
    background: #ffffff !important;
    border: 0 !important; }

/* POSITIONS START */
.top-left .mfp-content .popup-content,
.top-center .mfp-content .popup-content,
.top-right .mfp-content .popup-content {
  vertical-align: top;
  margin-top: 5px; }

.middle-left .mfp-content .popup-content,
.middle-center .mfp-content .popup-content,
.middle-right .mfp-content .popup-content {
  vertical-align: middle; }

.bottom-left .mfp-content .popup-content,
.bottom-center .mfp-content .popup-content,
.bottom-right .mfp-content .popup-content {
  vertical-align: bottom;
  margin-bottom: 5px; }

.top-left .mfp-container,
.middle-left .mfp-container,
.bottom-left .mfp-container {
  text-align: left; }

.top-right .mfp-container,
.middle-right .mfp-container,
.bottom-right .mfp-container {
  text-align: right; }

.top-center .mfp-container,
.middle-center .mfp-container,
.bottom-center .mfp-container {
  text-align: center; }

/* POSITIONS END */
.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 30px;
  height: 30px;
  line-height: 30px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1; }

.mfp-close:active {
  top: 1px; }

.mfp-close:hover {
  background: transparent !important;
  border: none !important; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1; }

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.popup-content.mfp-with-anim .admin__data-grid-outer-wrap
.product-item {
  text-align: left;
  display: table-cell;
  width: auto !important;
  padding-left: 20px !important; }

.popup-content.mfp-with-anim .admin__data-grid-outer-wrap
.product-item .product-item-actions {
  margin: 0; }
