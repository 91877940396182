.socials {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  @include breakpoint($desktop) {
    margin: 32px 0 107px 0;
  }
  .icon {
    height: 32px;
    width: 32px;
    margin: 0 8px;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.quality-products {
  img {
    margin-top: 32px;
    @include breakpoint($desktop) {
      max-width: 616px;
    }
  }
}
.facebook-icon {
  background-image: url(../images/icons/icon-facebook.svg);
}
.insta-icon {
  background-image: url(../images/icons/icon-instagram.svg);
}
.twitter-icon {
  background-image: url(../images/icons/icon-twitter.svg);
}
.youtube-icon {
  background-image: url(../images/icons/icon-youtube.svg);
}

.main {
  .about-us-grid {
    @include breakpoint($desktop) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 32px;
      grid-column-gap: 36px;
    }
    p {
      @include breakpoint($desktop) {
        margin-top: 0;
      }
    }
  }
}

.grid-area-1 {
  @include breakpoint($desktop) {
    order: 1;
  }
}
.grid-area-2 {
  @include breakpoint($desktop) {
    order: 2;
  }
}
.grid-area-3 {
  @include breakpoint($desktop) {
    order: 4;
  }
}
.grid-area-4 {
  @include breakpoint($desktop) {
    order: 3;
  }
}
