/*
====== Zoom effect ======
*/
.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */
}

.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-zoom-in.mfp-removing .mfp-with-anim {
  transform: scale(0.8);
  opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/*
====== Newspaper effect ======
*/
.mfp-newspaper {
  /* start state */
  /* animate in */
  /* animate out */
}

.mfp-newspaper .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.5s;
  transform: scale(0) rotate(500deg);
}

.mfp-newspaper.mfp-bg {
  opacity: 0;
  transition: all 0.5s;
}

.mfp-newspaper.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1) rotate(0deg);
}

.mfp-newspaper.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-newspaper.mfp-removing .mfp-with-anim {
  transform: scale(0) rotate(500deg);
  opacity: 0;
}

.mfp-newspaper.mfp-removing.mfp-bg {
  opacity: 0;
}

/*
====== Move-horizontal effect ======
*/
.mfp-move-horizontal {
  /* start state */
  /* animate in */
  /* animate out */
}

.mfp-move-horizontal .mfp-with-anim {
  opacity: 0;
  transition: all 0.3s;
  transform: translateX(-50px);
}

.mfp-move-horizontal.mfp-bg {
  opacity: 0;
  transition: all 0.3s;
}

.mfp-move-horizontal.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: translateX(0);
}

.mfp-move-horizontal.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-move-horizontal.mfp-removing .mfp-with-anim {
  transform: translateX(50px);
  opacity: 0;
}

.mfp-move-horizontal.mfp-removing.mfp-bg {
  opacity: 0;
}

/*
====== Move-from-top effect ======
*/
.mfp-move-from-top {
  /* start state */
  /* animate in */
  /* animate out */
}

.mfp-move-from-top .mfp-with-anim {
  opacity: 0;
  transition: all 0.2s;
  transform: translateY(-100px);
}

.mfp-move-from-top.mfp-bg {
  opacity: 0;
  transition: all 0.2s;
}

.mfp-move-from-top.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: translateY(0);
}

.mfp-move-from-top.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-move-from-top.mfp-removing .mfp-with-anim {
  transform: translateY(-50px);
  opacity: 0;
}

.mfp-move-from-top.mfp-removing.mfp-bg {
  opacity: 0;
}

/*
====== 3d unfold ======
*/
.mfp-3d-unfold {
  /* start state */
  /* animate in */
  /* animate out */
}

.mfp-3d-unfold .mfp-content {
  perspective: 2000px;
}

.mfp-3d-unfold .mfp-with-anim {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform-style: preserve-3d;
  transform: rotateY(-60deg);
}

.mfp-3d-unfold.mfp-bg {
  opacity: 0;
  transition: all 0.5s;
}

.mfp-3d-unfold.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: rotateY(0deg);
}

.mfp-3d-unfold.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-3d-unfold.mfp-removing .mfp-with-anim {
  transform: rotateY(60deg);
  opacity: 0;
}

.mfp-3d-unfold.mfp-removing.mfp-bg {
  opacity: 0;
}

/*
====== Zoom-out effect ======
*/
.mfp-zoom-out {
  /* start state */
  /* animate in */
  /* animate out */
}

.mfp-zoom-out .mfp-with-anim {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: scale(1.3);
}

.mfp-zoom-out.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.mfp-zoom-out.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}

.mfp-zoom-out.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-zoom-out.mfp-removing .mfp-with-anim {
  transform: scale(1.3);
  opacity: 0;
}

.mfp-zoom-out.mfp-removing.mfp-bg {
  opacity: 0;
}

/**
  ====== Fade-zoom effect ======
 */
/* start state */
.mfp-fade-zoom .zoom-anim-dialog {
  opacity: 0;

  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

/* animate in */
.mfp-fade-zoom.mfp-ready .zoom-anim-dialog {
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/* animate out */
.mfp-fade-zoom.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);

  opacity: 0;
}

/* Dark overlay, start state */
.mfp-fade-zoom.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

/* animate in */
.mfp-fade-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

/* animate out */
.mfp-fade-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

/**
 ====== Fade-slide effect ======
 */

/* at start */
.mfp-fade-slide .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;

  -webkit-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -moz-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -ms-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  transform: translateY(-20px) perspective(600px) rotateX(10deg);
}

/* animate in */
.mfp-fade-slide.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: translateY(0) perspective(600px) rotateX(0);
  -moz-transform: translateY(0) perspective(600px) rotateX(0);
  -ms-transform: translateY(0) perspective(600px) rotateX(0);
  -o-transform: translateY(0) perspective(600px) rotateX(0);
  transform: translateY(0) perspective(600px) rotateX(0);
}

/* animate out */
.mfp-fade-slide.mfp-removing .zoom-anim-dialog {
  opacity: 0;

  -webkit-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -moz-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -ms-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  transform: translateY(-10px) perspective(600px) rotateX(10deg);
}