.cms-page-view {
  img {
    @include breakpoint($desktop) {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }

  .main {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }

  .page-main {
    margin-top: 32px;
  }

  .grid-container {
    @include breakpoint($desktop) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 36px;
    }

    img {
      @include breakpoint($desktop) {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .grid-container + h2 {
    margin-top: 48px;
  }

  .cms-text-content {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .gr-divider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 48px 0;
    line-height: 14px;
    @include breakpoint($desktop) {
      margin: 64px 0;
    }

    &::after {
      width: calc(100% - 29px);
      height: 1px;
      background-color: $dividerGrey;
      margin: 6px 0;
      content: "";
      display: inline-block;
    }

    &::before {
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background-color: $grey;
      content: "";
      display: inline-block;
    }
  }

  .center-align {
    text-align: center;
    padding: 0 16px;
  }

  .page-wrapper {
    .page-main {
      padding: 0 16px 58px 16px;
      @include breakpoint($tablet) {
        padding: 0 24px 90px 24px;
      }
      @include breakpoint($desktop) {
        padding: 0 86px 90px 86px;
      }

      .page-title-wrapper {
        margin: 40px 0 0 0;
        text-align: center;

        .page-title {
          font-size: 32px;
          letter-spacing: 0;
          line-height: 33px;
          margin-bottom: 0;
        }
      }

      p {
        margin: 20px 0;
      }

      p.bold-first {
        font-weight: 600;
        text-align: center;
        color: $darkText;
        margin-top: 0;
        margin-bottom: 24px;
      }

      h2 {
        margin: 0 0 20px 0;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 24px;
      }

      h3 {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        margin-top: 30px;

        &:first-child {
          margin-top: 0;
        }
      }

      p + h2 {
        margin-top: 48px;
      }

      a {
        color: inherit;
        text-decoration: underline;
      }

      ul {
        list-style: none;
        padding-left: 28px;
      }

      li {
        margin-bottom: 24px;
      }

      li::before {
        content: "•";
        display: inline-block;
        height: 20px;
        width: 18px;
        background-size: 8px;
        background-repeat: no-repeat;
        margin-left: -22px;
        background-position: center;
      }
    }
  }

  h4 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }

  .column.main {
    padding-top: 24px;
    @include breakpoint($desktop) {
      padding-top: 32px;
    }
  }

  // Customer service page
  .side-tabs {
    ul.tab-nav {
      padding: 0;
      list-style: none;
      width: 100%;
      margin: 0 0 20px;
      float: none;

      @include breakpoint($tablet) {
        margin: 0;
        width: 200px;
        float: left;
      }

      li {
        &::before {
          display: none;
        }

        &.ui-state-active a {
          background-color: $primaryGreen;
        }
      }

      .ui-tabs-anchor {
        line-height: 1.5;
        padding: 12px 15px;
      }
    }

    .tab-container {
      padding-left: 0;

      @include breakpoint($tablet) {
        padding-left: 20px;
      }
    }
  }

  .ui-tabs-panel.tab-content {
    & > h4 {
      margin-top: 0;
    }
  }
}

.cms-page-block {
  .cms-sidebar {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        // sass-lint:disable-all
        padding: 0 !important;
        margin: 0 0 5px !important;
        // sass-lint:enable-all

        a {
          background: #F5F5F5;
          padding: 12px 15px;
          border-radius: 2px;
          display: block;
          color: #444;
          border: 1px solid #DDD;

          &.active {
            color: #fff;
            background: #396;
          }
        }

        &::before {
          // sass-lint:disable-all
          display: none !important;
          // sass-lint:enable-all
        }
      }
    }
  }
}

.contact-index-index .column .contact-container {
  margin: 0;
}

.contact-index-index {
  .form-area {
    .page-title {
      &::before {
        position: relative;
        content: "How Can We Help You?";
        font-size: 20px;
        padding: 17px 0;
        text-transform: uppercase;
        font-weight: 400;
        color: #303030;
        margin: 2rem 0;
        line-height: 1.1;
        display: block;
      }
    }

    h1 {
      display: none;
    }
  }
}
