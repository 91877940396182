.theme-pearl {
  .grey-background {
    .row {
      //important required to override important in welt pixel theme
      // sass-lint:disable-all
      max-width: 1170px !important;
      // sass-lint:enable-all
    }
  }
}

.page-wrapper .page-header .header-global-promo .global-notification-wrapper {
  padding: 5px 0;

  a {
    text-decoration: none;
  }
}

// sass-lint:disable-all
.header-global-promo #slides ul li .quoteContainer .wpx-link {
  text-decoration: none !important;
}
// sass-lint:enable-all

.custom-slider {
  .owl-carousel .owl-stage-outer {
    // sass-lint:disable-all
    padding: 0 !important;
    // sass-lint:enable-all
  }
}

.page-header .header-global-promo .global-notification-wrapper a.close-global-notification {
  top: 6px;
}

.sections.nav-sections-3.nav-sections.too-wide {
  width: 100%;
  // sass-lint:disable-all
  text-align: center !important;
  // sass-lint:enable-all
  margin: 10px 0 0;

  .fullwidth-wrapper {
    // sass-lint:disable-all
    text-align: left !important;
    // sass-lint:enable-all
  }
}

.navigation .megamenu.level-top-fullwidth .level0.fullwidth .fullwidth-wrapper .fullwidth-wrapper-inner .columns-group {
  @include breakpoint($desktop) {
    // sass-lint:disable-all
    width: 20% !important;
    // sass-lint:enable-all
  }
}

.navigation .megamenu.level-top-fullwidth .submenu [data-has-children] > a span:last-child,
.navigation .megamenu.level-top-sectioned .submenu [data-has-children] > a span:last-child,
.navigation .megamenu.level-top-boxed .submenu [data-has-children] > a span:last-child {
  display: block;
  line-height: 1.3;
}

.most-popular-block {
  max-width: 1200px;
  padding: 0;
  margin: 0 auto;

  ul {
    margin: 0 0 40px;
    padding: 0;
    display: flex;
    list-style: none;
    flex-wrap: wrap;

    @include breakpoint($tablet) {
      flex-wrap: nowrap;
    }

    li {
      width: 50%;
      margin: 0 0 20px;
      padding: 0 10px;

      h5 {
        font-family: "Source Code Pro", sans-serif;
        font-size: 15px;
      }

      &:last-child {
        display: none;
      }

      @include breakpoint($tablet) {
        width: 20%;

        &:last-child {
          display: block;
        }
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  img {
    width: 100%;
  }
}

@media (min-width: 1024px), print {
  .content-v5 .box-content .page-small-width h4 {
    margin-top: 0;
  }
  .content-v5 .content-opengreen-light h4 {
    // sass-lint:disable-all
    margin-top: 100px !important;
    // sass-lint:enable-all
  }
}

.theme-pearl .grey-background {
  padding: 50px 0 20px;
}
