.cms-shop-by-brand.cms-page-view {
  .columns {
    ul {
      padding-left: 0;
      li::before {
        content: "";
        height: 100%;
        margin-left: 0;
      }
    }
  }
}

.shop-by-brands {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;

  a {
    display: block;
    margin: 25px;
    flex-basis: 35%;
    @include breakpoint($desktop) {
      flex-basis: unset;
    }
  }

}

.clients-grid {
  li::before {
    // sass-lint:disable-all
    display: none !important;
    // sass-lint:enable-all
  }
}
